import React from 'react'
import { NavLink } from 'ion-navchevron'
import 'semantic-ui-css/components/menu.min.css'
import { LinkedIn, Facebook, Twitter, Instagram, Close, Search, Youtube, Pinterest, TikTok } from 'ion-icon'

const NavMenu = ({ toggleMenu }) => (
  <nav role='navigation'>
    <button className='close-btn' onClick={toggleMenu}><Close width='20' height='20' /></button>
    <ul className='menu-social'>
      <li>
        <a href={process.env.RAZZLE_SITE_LINKEDIN} target='_blank' rel='noopener noreferrer' title='Follow Glamour on LinkedIn'>
          <LinkedIn width='24' height='24' />
        </a>
      </li>
      <li>
        <a href={process.env.RAZZLE_SITE_FACEBOOK} target='_blank' rel='noopener noreferrer' title='Like Glamour on Facebook'>
          <Facebook width='24' height='24' />
        </a>
      </li>
      <li>
        <a href={process.env.RAZZLE_SITE_INSTAGRAM} target='_blank' rel='noopener noreferrer' title='Follow Glamour on Instagram'>
          <Instagram width='24' height='24' />
        </a>
      </li>
      <li>
        <a href={process.env.RAZZLE_SITE_TWITTER} target='_blank' rel='noopener noreferrer' title='Follow Glamour on Twitter'>
          <Twitter width='24' height='24' fill='white' />
        </a>
      </li>
      <li>
        <a href={process.env.RAZZLE_SITE_YOUTUBE} target='_blank' rel='noopener noreferrer' title='Subscribe to Glamour on Youtube'>
          <Youtube width='24' height='24' />
        </a>
      </li>
      <li>
        <a href={process.env.RAZZLE_SITE_TIKTOK} target='_blank' rel='noopener noreferrer' title='Subscribe to Glamour on Youtube'>
          <TikTok width='24' height='24' />
        </a>
      </li>
      <li>
        <a href={process.env.RAZZLE_SITE_PINTEREST} target='_blank' rel='noopener noreferrer' title='Follow Glamour on Pinterest'>
          <Pinterest width='24' height='24' />
        </a>
      </li>
    </ul>
    <div className='mobile-nav-search'>
      <form action='/search' method='get'>
        <input type='search' placeholder='Search Glamour' name='q' id='q' />
        <button><Search width='20' height='20' /></button>
      </form>
    </div>
    <ul className='main-nav'>
      <li>
        <NavLink to='/fashion'>Fashion</NavLink>
        <ul className='sub-nav'>
          <li>
            <NavLink to='/fashion/trends'>Trends</NavLink>
          </li>
          <li>
            <NavLink to='/fashion/celebrity-style'>Celebrity Style</NavLink>
          </li>
          <li>
            <NavLink to='/fashion/accessories'>Accessories</NavLink>
          </li>
          <li>
            <NavLink to='/fashion/style-diary'>Style Diary</NavLink>
          </li>
        </ul>
      </li>
      <li>
        <NavLink to='/beauty'>Beauty</NavLink>
        <ul className='sub-nav'>
          <li>
            <NavLink to='/beauty/hair'>Hair</NavLink>
          </li>
          <li>
            <NavLink to='/beauty/trends'>Trends</NavLink>
          </li>
          <li>
            <NavLink to='/beauty/makeup'>Makeup</NavLink>
          </li>
          <li>
            <NavLink to='/beauty/skincare'>Skin Care</NavLink>
          </li>
          <li>
            <NavLink to='/beauty/nails'>Nails</NavLink>
          </li>
          <li>
            <NavLink to='/beauty/beauty-awards'>Beauty Awards</NavLink>
          </li>
        </ul>
      </li>
      <li>
        <NavLink to='/celebrity-news'>Celebs</NavLink>
      </li>
      <li>
        <NavLink to='/lifestyle'>Lifestyle</NavLink>
        <ul className='sub-nav'>
          <li>
            <NavLink to='/lifestyle/careers'>Careers</NavLink>
          </li>
          <li>
            <NavLink to='/lifestyle/food-drink'>Food & Drink</NavLink>
          </li>
          <li>
            <NavLink to='/lifestyle/travel'>Travel</NavLink>
          </li>
          <li>
            <NavLink to='/lifestyle/weddings'>Weddings</NavLink>
          </li>
          <li>
            <NavLink to='/lifestyle/man-candy'>Man Candy</NavLink>
          </li>
          <li>
            <NavLink to='/lifestyle/glamour-guides'>Glam Guides</NavLink>
          </li>
          <li>
            <NavLink to='/lifestyle/glam-book-club'>GLAM Book Club</NavLink>
          </li>
          <li>
            <NavLink to='/lifestyle/women-in-charge'>GLAM Book Club</NavLink>
          </li>
        </ul>
      </li>
      <li>
        <NavLink to='/wellness'>Wellness</NavLink>
        <ul className='sub-nav'>
          <li>
            <NavLink to='/wellness/fitness-exercise'>Fitness &amp; Exercise</NavLink>
          </li>
          <li>
            <NavLink to='/wellness/love-relationships'>Love &amp; Relationships</NavLink>
          </li>
          <li>
            <NavLink to='/wellness/mindfulness'>Mindfulness</NavLink>
          </li>
        </ul>
      </li>
      <li>
        <NavLink to='/Events'>Events</NavLink>
      </li>
      <li>
        <NavLink to='/win'>Win</NavLink>
      </li>
      <li>
        <NavLink to='/more'>More</NavLink>
        <ul className='sub-nav'>
          <li>
            <NavLink to='/more/gift-guide'>Gift Guide</NavLink>
          </li>
          <li>
            <NavLink to='/more/glam-x-skip'>GLAM x SKIP</NavLink>
          </li>
          <li>
            <NavLink to='/more/glam-x-ciao-malfy'>GLAM x Ciao Malfy</NavLink>
          </li>
          <li>
            <NavLink to='/more/glam-x-mac'>GLAM x MAC</NavLink>
          </li>
          {/* <li>
            <NavLink to='/more/sparkle-on-with-bernini'>Sparkle on with Bernini</NavLink>
          </li>
          <li>
            <NavLink to='/more/glam-x-ariel'>GLAM x Ariel</NavLink>
          </li>
          <li>
            <NavLink to='/more/glam-x-bulgari'>GLAM x Bulgari</NavLink>
          </li> */}
          <li>
            <NavLink to='/more/glams-most-glam'>GLAM's Most GLAM</NavLink>
          </li>
          {/* <li>
            <NavLink to='/more/style-your-smile'>#StyleYourSmile</NavLink>
          </li> */}
          <li>
            <NavLink to='/more/women-of-the-year'>Women of the Year</NavLink>
          </li>
          <li>
            <NavLink to='/more/betway-summer-cup'>Betway Summer Cup</NavLink>
          </li>
          {/* <li>
            <NavLink to='/more/how-do-i-look-sa'>How Do I Look? SA</NavLink>
          </li> */}
        </ul>
      </li>
    </ul>
  </nav>
)

export default NavMenu
